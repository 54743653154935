import server from '@/api/server'

const getProductsById = async (productIds) => {
	try {
		const { data } = await server.post('/stripe-payment-gateway/v1/products-by-id', {productIds})
		return data.results
	} catch(err) {
		console.log('err', err);
		if(err.response)
			return err.response.data

	}
}

export default getProductsById