import server from '@/api/server'
import { useProductsStore } from '@/stores/products'
import { ref } from 'vue'

export default function useSearch() {
    const isLoading = ref(false)
    const { buildProductObject } = useProductsStore()

    async function fetchSearch(input) {
        if(isLoading.value)
            return

        try {
            isLoading.value = true
            
            const { data } = await server.get('/stripe-payment-gateway/v1/products', {
                params: { search: input }
            });
            
            const products = data.results.map(product => {
                const variations = product.variations?.map(variation => buildProductObject(variation, product)) || []
                const prd = buildProductObject(product);
                return {
                    ...prd,
                    variations
                }
            });

            return products
        } catch(error) {
            return []
        } finally {
            isLoading.value = false
        }
    }

    return {
        fetchSearch,
        isLoading
    }
}