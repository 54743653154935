<template>
    <div class="col-md-7 col-lg-8 order-md-first">
        <div class="card border-0">
            <div class="card-body px-4">
                <h4 class="my-3">Dirección de facturación y envío</h4>
                <hr>
                <form class="needs-validation" @submit.prevent="handleCheckout()">
                    <div class="row g-3 mb-3">
                        <div class="col-sm-6">
                            <label for="firstName" class="form-label">Nombre</label>
                            <input type="text" class="form-control" id="firstName" placeholder="" v-model="formData.billing.first_name" required="">
                        </div>

                        <div class="col-sm-6">
                            <label for="lastName" class="form-label">Apellidos</label>
                            <input type="text" class="form-control" id="lastName" placeholder="" v-model="formData.billing.last_name" required="">
                        </div>

                        <div class="col-12">
                            <label for="email" class="form-label">Correo electrónico</label>
                            <input type="email" class="form-control" id="email" placeholder="you@example.com" v-model="formData.billing.email">
                        </div>

                        <div class="col-12">
                            <label for="address" class="form-label">Dirección</label>
                            <input type="text" class="form-control" id="address" placeholder="1234 Main St" required="" v-model="formData.billing.address_1">
                        </div>

                        <div class="col-12">
                            <label for="address2" class="form-label">Dirección 2 <span class="text-muted">(Opcional)</span></label>
                            <input type="text" class="form-control" id="address2" placeholder="Apartment or suite" v-model="formData.billing.address_2">
                        </div>

                        <div class="col-12">
                            <label for="city" class="form-label">Ciudad</label>
                            <input type="text" class="form-control" id="city" placeholder="" required="" v-model="formData.billing.city">
                        </div>

                        <div class="col-md-5">
                            <label for="country" class="form-label">País</label>
                            <select class="form-select" id="country" required="" @change="updateState()" v-model="formData.billing.country">
                                <option value="">- Selecciona -</option>
                                <option 
                                    v-for="country in store.countries" 
                                    :value="country.code" 
                                    :key="country.name">
                                    {{country.name}}
                                </option>
                            </select>
                        </div>

                        <div class="col-md-4">
                            <label for="state" class="form-label">Estado</label>
                            <select class="form-select" id="state" @change="updateState()" v-model="formData.billing.state">
                                <option value="">- Selecciona -</option>
                                <option 
                                    v-for="state in states" 
                                    :value="state.code" 
                                    :key="state.name">
                                    {{state.name}}
                                </option>
                            </select>
                        </div>

                        <div class="col-md-3">
                            <label for="zip" class="form-label">CP</label>
                            <input type="text" class="form-control" id="zip" placeholder="" required="" v-model="formData.billing.postcode">
                        </div>

                        <div class="col-12">
                            <label for="address2" class="form-label">Notas <span class="text-muted">(Opcional)</span></label>
                            <textarea class="form-control" v-model="formData.notes" placeholder="Notas"></textarea>
                        </div>
                    </div>

                    <div class="form-check">
                        <input type="checkbox" v-model="updateProfile" class="form-check-input" id="save-info">
                        <label class="form-check-label" for="save-info">Guarde esta información para la próxima vez</label>
                    </div>
                    
                    <button type="submit" class="mt-3 btn-expanded w-100 btn btn-theme" :disabled="chStore.isLoading ? true : false">
                        <span>Confirmar pedido</span>

                        <div v-if="chStore.isLoading" class="spinner-border me-1 ms-3 spinner-border-sm text-light" role="status">
                            <span class="visually-hidden">Cargando</span>
                        </div>
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, onMounted, ref } from "vue";
import { useCheckoutStore } from "@/stores/checkout";
import { useGlobalStore } from "@/stores/globalStore";
import ShippingMethods from "./ShippingMethods.vue";
import useCheckout from "@/composables/useCheckout";

const emits = defineEmits(['on-submit'])
const store = useGlobalStore()
const chStore = useCheckoutStore()
const formData = ref({})
const updateProfile = ref(false)
const states = ref([])
const { buildOrder } = useCheckout()

onBeforeMount( () => {
    formData.value = { ...store.auth }
    if(Object.keys(formData.value).length == 0) {
        formData.value = {
            billing: {}
        }
    }
})

onMounted(() => {
    updateState();
})

async function handleCheckout() {
    const orderData = buildOrder(
        formData.value.id, 
        formData.value.billing, 
        chStore.getShippingMethod, 
        chStore.getCoupon?.code,
        formData.value.notes
    )

    if(orderData) {
        const resp = await chStore.createAnOrder(orderData, updateProfile.value)
        emits('on-submit', resp)
    }
}

function updateState() {
    chStore.setShippingMethod(null)
    let countrySelected = store.countries.find(el => el.code == formData.value.billing?.country)
    console.log('Updatestate', countrySelected);
    if(countrySelected) {
        states.value = countrySelected.states
        checkShipping(countrySelected.name)
    }
}

async function checkShipping(country) {
    const shippingZoneAvailable = chStore.getShippingZones.find(el => el.name == country)
    console.log('Metodos de envio disponibles', shippingZoneAvailable)
    if(shippingZoneAvailable)
        chStore.setShippingMethod(shippingZoneAvailable.shipping_methods[0])
}
</script>

<style scoped>
    .btn-expanded {
        width: 100%;
        padding: 16px!important;
        border-radius: 16px!important;
    }
</style>