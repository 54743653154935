<template>
    <header class="header-top card rounded-0 border-0">
        <div class="card-body py-4 d-flex align-items-center">
            <div class="search-box">
                <div class="input-group bg-light rounded p-1">
                    <transition name="list">
                        <span class="input-group-text bg-light border-0" :key="`loading-${isLoading}`">
                            <i v-if="!isLoading" class="bi bi-search"></i>
                            <div v-else class="spinner-border spinner-border-sm text-theme" role="status">
                                <span class="visually-hidden">Cargando...</span>
                            </div>
                        </span>
                    </transition>
                    <input type="text" v-model="inputSearch" class="form-control border-0 bg-light" placeholder="Buscar producto..." @input="handleSearch()">
                    <button v-if="inputSearch.length > 0" class="btn" type="button" @click="clearSearch()">
                        <i class="bi bi-x"></i>
                    </button>
                    <button :disabled="isLoading ? true : false" class="btn btn-light bg-white rounded" type="button" @click="handleSearch()">
                        <i class="bi bi-upc-scan"></i>
                    </button>
                </div>

                <search-products-container v-if="items" :items="items" @added-to-cart="clearSearch()" />
            </div>

           
            <div class="ms-auto text-end right-actions" v-if="global.status === 'authorised'">
                <button @click="display = true" class="btn btn-lg ms-2 btn-light text-theme rounded py-2">
                    <span class="text-theme small me-1" v-if="chStore.getCart.length > 0">{{ chStore.getCart.length }}</span>
                    <i class="bi bi-cart"></i>
                </button>

                <!-- TODO: Agregar funcionalidad -->
                <!-- <button class="btn btn-lg ms-2 btn-light text-theme rounded py-2">
                    <i class="bi bi-sliders"></i>
                </button> -->
            </div>
        </div>
        <cart-modal v-if="display" :display="display" @on-close="display = false" />
    </header>
</template>

<script setup>
import { ref } from 'vue';
import useSearch from '@/composables/useSearch'
import CartModal from '@/components/cart/CartModal.vue'
import SearchProductsContainer from '../common/SearchProductsContainer.vue'
import { useCheckoutStore } from '@/stores/checkout';
import { useGlobalStore } from '@/stores/globalStore';

const { fetchSearch, isLoading } = useSearch()
const global = useGlobalStore()
const chStore = useCheckoutStore()
const display = ref(false)
const inputSearch = ref('')
const items = ref([])
const searchTimeout = ref(null)

const handleSearch = () => {
    clearTimeout(searchTimeout.value);

    if (inputSearch.value.length > 2) {
        searchTimeout.value = setTimeout(async () => {
            items.value = await fetchSearch(inputSearch.value);
        }, 800);
    } else {
        items.value = [];
    }
};

const clearSearch = () => {
    inputSearch.value = ''
    items.value = []
}
</script>

<style scoped>
.header-top .card-body {
    min-height: 102px;
    border-left: 2px solid #e5e5e5;
}
.results-container {
    z-index: 9;
}
.right-actions {
    min-width: 140px;
}
.search-box {
    width: 100%;
    max-width: 400px;
}
</style>