import { computed, ref } from 'vue'
import { dateFormatted } from '@/helpers/moment'
import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'
import server from '@/api/server'

export const useCheckoutStore = defineStore('checkoutStore', () => {
    const cart = useLocalStorage('cart', [])
    const shippingZones = ref([])
    const shippingMethod = ref(null)
    const orderPending = ref(null)
    const isLoading = ref(false)
    const coupon = ref(null)
    const subtotal = computed(() => {
        return cart.value.length > 0
            ? cart.value.reduce((acc, prd) => parseFloat(acc + (prd.prices.price * prd.quantity)), 0)
            : 0
    })

    const cartTax = computed(() => {
        const shippingTax = shippingMethod.value ? (getShippingCost.value * 0.21) : 0

        return cart.value.length > 0
            ? cart.value.reduce((acc, prd) => parseFloat(acc + (prd.prices.tax_amount * prd.quantity)), 0) + shippingTax
            : 0
    })

    async function fetchShippingZones() {
        try {
            isLoading.value = true;
            const { data } = await server.get('/wc/v3/shipping/zones');
    
            const shippingZonesData = await Promise.all(data.map(async (element) => {
                const [respLocation, respShippingMethods] = await Promise.all([
                    server.get(`/wc/v3/shipping/zones/${element.id}/locations`),
                    server.get(`/wc/v3/shipping/zones/${element.id}/methods`)
                ]);
    
                return {
                    ...element,
                    locations: respLocation.data,
                    shipping_methods: respShippingMethods.data
                };
            }));
    
            shippingZones.value = shippingZonesData;
        } catch (err) {
            console.error('Error al obtener las zonas de envío:', err);
            shippingZones.value = [];
        } finally {
            isLoading.value = false;
            console.log('Zonas de envío cargadas:', shippingZones.value);
        }
    }

    function cloneCart(data) {
        cart.value = data
    }

    function addToCart(data) {
        const exists = cart.value.find(el => el.id === data.id)

        if(!exists) {
            cart.value.push({ ...data, quantity: 1})
        } else {
            addQuantity(data.id)
        }
    }

    function removeFromCart(id) {
        const index = cart.value.findIndex(el => el.id === id)
        cart.value.splice(index, 1)
    }

    function addQuantity(id) {
        const index = cart.value.findIndex(el => el.id === id)
        cart.value[index].quantity++
    }

    function removeQuantity(id) {
        const index = cart.value.findIndex(el => el.id === id)
        const item = cart.value[index]

        if(item.quantity === 1) {
            cart.value.splice(index, 1)
        } else {
            cart.value[index].quantity--
        }
    }

    async function createAnOrder(orderData, updateProfile) {
        try {
            if(updateProfile)
                server.post(`/wc/v3/customers/${orderData.customer_id}`, 
                
                {
                    billing: orderData.billing,
                    shipping: orderData.shipping
                })

            isLoading.value = true
            const { data } = await server.post('/stripe-payment-gateway/v1/create-order', orderData)
            orderPending.value = JSON.parse(data)
            isLoading.value = false

            return orderPending.value
        } catch(err) {
            console.log(err, 'error')
            return null
        }
    }
    
    function clearCheckout() {
        cart.value = []
        coupon.value = null
        shippingMethod.value = null
        orderPending.value = null
    }

    async function setCoupon(value) {
        coupon.value = null

        if(!value)
            return null

        try {
            const { data } = await server.get(`/wc/v3/coupons?code=${value}`)
            
            if(data.length == 0)
                return null
            
            let response = data[0]
            
            if(parseFloat(subtotal.value) < parseFloat(response.minimum_amount) && parseFloat(response.minimum_amount) != 0 ) 
                return null

            if(parseFloat(subtotal.value) > parseFloat(response.maximum_amount) && parseFloat(response.maximum_amount) != 0) 
                return null

            if(dateFormatted() > dateFormatted(response.date_expires))
                return null


            coupon.value = data[0]
            return coupon.value
        } catch(err) {
            console.log(err, 'error')
            return false
        }
    }

    const getSubtotalIntern = computed( () => {
        let amount = subtotal.value

        if(!coupon.value)
            return amount

        if(coupon.value.discount_type == 'percent') {
            amount = amount * (1 - parseFloat(coupon.value.amount) / 100)
        }

        if(coupon.value.discount_type == 'fixed_cart') {
            amount = amount - parseFloat(coupon.value.amount)

            if(amount < 0)
                amount = 0.00
        }

        return amount
    })

    const getTotal = computed( () => {
        let total = (parseFloat(getSubtotalIntern.value)) + parseFloat(cartTax.value)

        if(shippingMethod.value)
            total += getShippingCost.value

        return total
    })

    const getShippingCost = computed(() => {
        let shippingCost = 0

        if(parseFloat(getSubtotalIntern.value) <= 49.99) {
            shippingCost = 3.84
        } else if(parseFloat(getSubtotalIntern.value) <= 99.99) {
            shippingCost = 3
        }

        return shippingCost
    })

    const getDiscount = computed( () => {
        let amount = subtotal.value

        if(!coupon.value)
            return 0.00

        if(coupon.value.discount_type == 'percent') {
            let aux = amount * (1 - parseFloat(coupon.value.amount) / 100)
            amount = amount - aux
        }

        if(coupon.value.discount_type == 'fixed_cart') {
            amount = parseFloat(coupon.value.amount)
        }


        return amount
    })

    function setShippingMethod(data) {
        shippingMethod.value = data
    }

    return { 
        addQuantity,
        addToCart,
        fetchShippingZones,
        createAnOrder,
        removeFromCart,
        removeQuantity,
        setCoupon,
        setShippingMethod,
        clearCheckout,
        cloneCart,
        getCart: computed( () => cart.value),
        getCoupon: computed( () => coupon.value),
        getDiscount,
        getShippingCost,
        getOrderPending: computed( () => orderPending.value),
        getShippingMethod: computed( () => shippingMethod.value),
        getShippingZones: computed( () => shippingZones.value),
        getSubtotal: subtotal,
        getTotal,
        getTax: cartTax,
        getSubtotalIntern,
        isLoading
    }
})
